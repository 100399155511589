import {ready} from "../utils/ready.mjs";
import {throttle} from "../utils/throttle.mjs";

// based on https://inclusive-components.design/a-content-slider/
;(function () {
    'use strict';

    /* touch detection */
    window.addEventListener('touchstart', function touched()
    {
        document.body.classList.add('touch');
        window.removeEventListener('touchstart', touched, false);
    }, false);

    // load galleries
    ready(function () {

        const galleries = document.querySelectorAll('.js-slider');
        if (galleries.length > 0) {
            Array.prototype.forEach.call(galleries, function (gallery) {

                let slides;

                const updateSlides = function() {
                    slides = gallery.querySelectorAll('li');

                    Array.prototype.forEach.call(slides, function (slide) {
                        var links = slide.querySelectorAll('a,button');
                        if (links.length > 0) {
                            Array.prototype.forEach.call(links, function (link) {
                                link.setAttribute('tabindex', '-1');
                            });
                        }
                    });
                }

                updateSlides();

                gallery.setAttribute('aria-label', 'gallery');
                gallery.setAttribute('role', 'region');
                gallery.setAttribute('tabindex', '0');

                const observerSettings = {
                    root: gallery,
                    threshold: [0,0.2,0.4,0.6,0.8,1]
                };

                if ('IntersectionObserver' in window) {
                    const scrollToSlide = function scrollToSlide(slideToShow)
                    {
                        if (slides.length > 0 && slides[slides.length - 1] === slideToShow) {
                            console.log('scroll to last slide: ' + gallery.scrollWidth);
                            gallery.scrollLeft = gallery.scrollWidth + 20;
                        } else {
                            var scrollPos = slideToShow.offsetLeft;
                            console.log('scroll slide: ' + scrollPos);
                            gallery.scrollLeft = scrollPos;
                        }
                    };

                    const moveSlider = function moveSlider(dir, slides)
                    {
                        let newSlide = false;
                        if (dir === 'previous') {
                            newSlide = findPreviousSlide(slides);
                        } else {
                            newSlide = findNextSlide(slides);
                        }
                        if (newSlide) {
                            scrollToSlide(newSlide);
                        }
                    };

                    const findPreviousSlide = function findPreviousSlide(slides)
                    {
                        let newSlide = false;
                        let visible = gallery.querySelectorAll('.visible');
                        if (slides.length > 0 && visible.length > 0) {
                            newSlide = visible[0];
                            let currentPos = newSlide.offsetLeft;
                            console.log('current pos: ' + currentPos);
                            while (newSlide !== slides[0] && newSlide.offsetLeft >= currentPos) {
                                if (newSlide.previousElementSibling !== null) {
                                    newSlide = newSlide.previousElementSibling;
                                }
                            }
                            console.log('next pos: ' + newSlide.offsetLeft);
                        } else if (slides.length > 0) {
                            console.log('first slide');
                            newSlide = slides[0];
                        }
                        return newSlide;
                    }

                    const findNextSlide = function findNextSlide(slides)
                    {
                        let newSlide = false;
                        let visible = gallery.querySelectorAll('.visible');
                        if (slides.length > 0 && visible.length > 0) {
                            // start with the first visible
                            newSlide = visible[0];
                            // go through slides and get next with position > than first visible (in case there are two rows)
                            let currentPos = newSlide.offsetLeft;
                            console.log('current pos: ' + currentPos);
                            while (newSlide !== slides[slides.length - 1] && newSlide.offsetLeft <= currentPos) {
                                if (newSlide.nextElementSibling !== null) {
                                    newSlide = newSlide.nextElementSibling;
                                }
                            }
                            // if we are at the end select last slide as target
                            if (slides[slides.length - 1] === visible[visible.length - 1]) {
                                newSlide = slides[slides.length - 1];
                            }
                            console.log('next pos: ' + newSlide.offsetLeft);
                        } else if (slides.length > 0) {
                            console.log('last slide');
                            newSlide = slides[slides.length - 1];
                        }
                        return newSlide;
                    }

                    const observerCallback = function callback(observedSlides, observer)
                    {
                        if (observedSlides !== undefined && observedSlides  !== null) {
                            Array.prototype.forEach.call(observedSlides, function (entry, index) {
                                entry.target.classList.remove('visible');
                                let links = entry.target.querySelectorAll('a,button');
                                Array.prototype.forEach.call(links, function (link) {
                                    link.setAttribute('tabindex', '-1');
                                });
                                if (entry.isIntersecting && entry.intersectionRatio >= 0.8) {
                                    entry.target.classList.add('visible');
                                    Array.prototype.forEach.call(links, function (link) {
                                        link.removeAttribute('tabindex', '-1');
                                    });
                                }
                            });
                            // check if last visible element is second last in list
                            if (!gallery.classList.contains('loading')
                                && slides[slides.length - 2].classList.contains('visible')
                            ) {
                                // load more
                                console.log('load more');
                                const url = gallery.getAttribute('data-load-more');
                                if (url !== undefined && url !== null && url.length > 0) {
                                    gallery.classList.add('loading');
                                    let xhr = new XMLHttpRequest();
                                    xhr.onreadystatechange = function () {
                                        const DONE = 4; // readyState 4 means the request is done.
                                        const OK = 200; // status 200 is a successful return.
                                        if (xhr.readyState === DONE) {
                                            if (xhr.status === OK) {
                                                Array.prototype.forEach.call(slides, function (t) {
                                                    return observer.unobserve(t);
                                                });
                                                gallery.querySelector('ul').insertAdjacentHTML('beforeend', xhr.response);
                                                gallery.setAttribute('data-load-more', xhr.getResponseHeader('x-load-more'));
                                                gallery.classList.remove('loading');
                                                updateSlides();
                                                Array.prototype.forEach.call(slides, function (t) {
                                                    return observer.observe(t);
                                                });
                                            }
                                        }
                                    };
                                    xhr.open('GET', url);
                                    xhr.send(null);
                                }
                            }
                        }
                    };

                    const observer = new IntersectionObserver(observerCallback, observerSettings);
                    Array.prototype.forEach.call(slides, function (slide) {
                        return observer.observe(slide);
                    });

                    let controls = document.createElement('ul');
                    controls.setAttribute('aria-label', 'gallery controls');
                    controls.classList.add('js-slider-controls');
                    controls.innerHTML = '<li><button type="button" class="previous" data-dir="previous" aria-label="previous"><span></span></button></li><li><button type="button" class="next" data-dir="next" aria-label="next"><span></span></button></li>';
                    gallery.parentNode.insertBefore(controls, null);

                    controls.addEventListener('click', function (e) {
                        console.log('click ' + e.target.closest('button').getAttribute('data-dir'));
                        moveSlider(e.target.closest('button').getAttribute('data-dir'), slides);
                    });

                    window.addEventListener('resize', throttle(function() {
                        console.log('resize');
                        if (
                            typeof(controls) !== 'undefined'
                            && controls !== null
                        ) {
                            console.log(gallery.offsetWidth + ' < ' + gallery.scrollWidth + '?');
                            if (gallery.offsetWidth < gallery.scrollWidth) {
                                controls.style.display = 'block';
                                gallery.setAttribute('tabindex', '0');
                                console.log('show');
                            } else {
                                controls.style.display = 'none';
                                gallery.removeAttribute('tabindex');
                                console.log('hide');
                            }
                        }
                    }, 200));
                    // trigger resize event
                    if (typeof(Event) === 'function') {
                        // modern browsers
                        window.dispatchEvent(new Event('resize'));
                    } else {
                        // for IE and other old browsers
                        // causes deprecation warning on modern browsers
                        var evt = window.document.createEvent('UIEvents');
                        evt.initUIEvent('resize', true, false, window, 0);
                        window.dispatchEvent(evt);
                    }
                }
            });
        }

    });

}());
