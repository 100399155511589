import {ready} from "../utils/ready.mjs";

(function () {
    'use strict';

    // init main navigation
    ready(function () {
        var button = document.querySelector('.js-menu-toggle');
        if (typeof(button) != 'undefined' && button != null) {
            var nav = new MainNavigation(button);
        }
    });

    // Constructor
    function MainNavigation(button)
    {
        var self = this;

        this.button = button;

        // get menu id
        if (this.button.hasAttribute('data-menu')) {
            this.navID = this.button.getAttribute('data-menu');
        }
        if (typeof(this.navID) == 'undefined' || this.navID == null) {
            throw new Error('Button has no data-menu attribute.');
        }

        // get nav
        this.nav = document.getElementById(this.navID);
        if (typeof(this.nav) == 'undefined' || this.nav == null) {
            throw new Error('Nav `#' + this.navID + '` does not exist.');
        }

        // Add (initial) button semantics
        this.button.setAttribute('aria-haspopup', true);
        this.button.setAttribute('aria-expanded', false);
        this.button.setAttribute('role', 'button');
        this.button.setAttribute('aria-controls', this.navID);

        // Handle button click
        this.button.addEventListener('click', function (e) {
            e.preventDefault();
            this.toggle();
        }.bind(this))

        // Also toggle on key interactions
        this.button.addEventListener('keydown', function (e) {

            if (e.keyCode === 13 || e.keyCode === 32) {
                // enter or space
                e.preventDefault();
                this.toggle();
            } else if (e.keyCode === 40) {
                // down arrow
                if (this.button.getAttribute('aria-expanded') !== 'true') {
                    e.preventDefault();
                    this.open();
                } else {
                    this.nav.querySelector('a').focus()
                }
            } else if ((e.shiftKey && e.keyCode === 9)) {
                // shift+tab
                this.close(false);
            } else if (e.keyCode === 9) {
                // or tab
                if (this.button.getAttribute('aria-expanded') === 'true') {
                    e.preventDefault();
                    this.nav.querySelector('a').focus()
                }
            } else if (e.keyCode === 38) {
                // up arrow
                this.close();
            } else if (e.keyCode === 27) {
                // escape
                this.close();
            }
        }.bind(this))

        // Get the all top level links within the menu
        this.menuLinks = this.nav.querySelectorAll('a');
        if (this.menuLinks.length < 1) {
            throw new Error('The #' + this.navId + ' menu has no menu items');
        }

        // Handle key presses for menuItem
        Array.prototype.forEach.call(this.menuLinks, function (link, index) {

            // add keyboard event
            link.addEventListener('keydown', function (e) {

                if (e.shiftKey && e.keyCode === 9) {
                    // shift+tab
                    focusNext(e, false);
                } else if (e.keyCode === 38) {
                    // up arrow
                    focusNext(e, false);
                } else if (e.keyCode === 40 || e.keyCode === 9) {
                    // down arrow or tab
                    focusNext(e, true);
                } else if (e.keyCode === 27) {
                    // escape
                    this.close();
                }

            }.bind(this))
        }.bind(this))

        var focusNext = function (event, down) {
            var link = event.target;
            var goingDown = down;

            // helper function for getting next legitimate element
            function getNextElement(link)
            {
                if (goingDown) {
                    if ( // check if there is a visible next nav element
                        typeof link.parentNode.nextElementSibling !== "undefined"
                        && link.parentNode.nextElementSibling != null
                        && !isElementHidden(link.parentNode.nextElementSibling)
                    ) {
                        console.log('down');
                        return link.parentNode.nextElementSibling.querySelector('a');
                    } else if ( // check if link in next section is available
                        typeof link.parentNode.parentNode.parentNode !== "undefined"
                        && link.parentNode.parentNode.parentNode != null
                        && link.parentNode.parentNode.parentNode.tagName === 'LI'
                        && typeof link.parentNode.parentNode.parentNode.nextElementSibling !== "undefined"
                        && link.parentNode.parentNode.parentNode.nextElementSibling != null
                        && !isElementHidden(link.parentNode.parentNode.parentNode.nextElementSibling)
                    ) {
                        console.log('down next section');
                        return link.parentNode.parentNode.parentNode.nextElementSibling.querySelector('a');
                    } else if ( // check if close button is available
                        typeof link.parentNode.parentNode.parentNode.parentNode.parentNode !== "undefined"
                        && link.parentNode.parentNode.parentNode.parentNode.parentNode != null
                        && link.parentNode.parentNode.parentNode.parentNode.parentNode.hasAttribute('id')
                        && typeof document.querySelector('button[data-menu="' + link.parentNode.parentNode.parentNode.parentNode.parentNode.getAttribute('id') + '"]') !== "undefined"
                        && document.querySelector('button[data-menu="' + link.parentNode.parentNode.parentNode.parentNode.parentNode.getAttribute('id') + '"]') != null
                        && !isElementHidden(document.querySelector('button[data-menu="' + link.parentNode.parentNode.parentNode.parentNode.parentNode.getAttribute('id') + '"]'))
                    ) {
                        console.log('button');
                        return document.querySelector('button[data-menu="' + link.parentNode.parentNode.parentNode.parentNode.parentNode.getAttribute('id') + '"]');
                    } else {
                        console.log('no move');
                        return link;
                    }
                } else {
                    if ( // check if there is a visible next nav element
                        typeof link.parentNode.previousElementSibling !== "undefined"
                        && link.parentNode.previousElementSibling != null
                        && !isElementHidden(link.parentNode.previousElementSibling)
                    ) {
                        console.log('up');
                        return link.parentNode.previousElementSibling.querySelector('a');
                    } else if ( // check if link in next section is available
                        typeof link.parentNode.parentNode.parentNode !== "undefined"
                        && link.parentNode.parentNode.parentNode != null
                        && link.parentNode.parentNode.parentNode.tagName === 'LI'
                        && typeof link.parentNode.parentNode.parentNode.previousElementSibling !== "undefined"
                        && link.parentNode.parentNode.parentNode.previousElementSibling != null
                        && !isElementHidden(link.parentNode.parentNode.parentNode.previousElementSibling)
                    ) {
                        console.log('up next section');
                        return link.parentNode.parentNode.parentNode.previousElementSibling.querySelector('li:last-child a');
                    } else if ( // check if close button is available
                        typeof link.parentNode.parentNode.parentNode.parentNode.parentNode !== "undefined"
                        && link.parentNode.parentNode.parentNode.parentNode.parentNode != null
                        && link.parentNode.parentNode.parentNode.parentNode.parentNode.hasAttribute('id')
                        && typeof document.querySelector('button[data-menu="' + link.parentNode.parentNode.parentNode.parentNode.parentNode.getAttribute('id') + '"]') !== "undefined"
                        && document.querySelector('button[data-menu="' + link.parentNode.parentNode.parentNode.parentNode.parentNode.getAttribute('id') + '"]') != null
                        && !isElementHidden(document.querySelector('button[data-menu="' + link.parentNode.parentNode.parentNode.parentNode.parentNode.getAttribute('id') + '"]'))
                    ) {
                        console.log('button');
                        return document.querySelector('button[data-menu="' + link.parentNode.parentNode.parentNode.parentNode.parentNode.getAttribute('id') + '"]');
                    } else {
                        console.log('no move');
                        return link;
                    }
                }
            }

            function isElementHidden(element)
            {
                return (element.offsetParent === null);
            }

            // don't allow default movement
            event.preventDefault();

            // make move
            var nextItem = getNextElement(link);
            if (nextItem !== link) {
                nextItem.focus();
            }

        }.bind(this)

        // register outside click
        this.outsideClick = function (e) {
            if (this.button.getAttribute('aria-expanded') === 'true' && !this.nav.contains(e.target) && !this.button.contains(e.target)) {
                this.close(false);
            }
        }
        document.addEventListener('click', this.outsideClick.bind(this));

        // initiate listeners object for public events
        this._listeners = {}
    }

    // Open methods
    MainNavigation.prototype.open = function () {
        // open nav
        this.button.setAttribute('aria-expanded', true);
        // mark body
        document.documentElement.classList.add('js-menu-open');
        // fire open event
        this._fire('open');
        return this;
    }
    MainNavigation.prototype.openSubmenu = function (button) {
        // open nav
        button.setAttribute('aria-expanded', true);
        // mark item
        button.parentNode.classList.add('js-is-open');
        // fire open event
        this._fire('openSubmenu');
        return this;
    }

    // Close methods
    MainNavigation.prototype.close = function (setFocus) {
        setFocus = typeof setFocus === "undefined" ? true : setFocus;
        // close nav
        this.button.setAttribute('aria-expanded', false);
        // mark body
        document.documentElement.classList.remove('js-menu-open');
        // set focus
        if (setFocus) {
            this.button.focus();
        }
        // fix hash
        if (typeof window.location.hash !== "undefined" && window.location.hash === "#nav") {
            var href = window.location.href.split('#')[0];
            if (history.pushState) {
                history.pushState(null, null, href);
            } else {
                window.location = href;
            }
        }
        // fire close event
        this._fire('close');
        return this;
    }
    MainNavigation.prototype.closeSubmenu = function (button, setFocus) {
        setFocus = typeof setFocus === "undefined" ? true : setFocus;
        // open nav
        button.setAttribute('aria-expanded', false);
        // mark item
        button.parentNode.classList.remove('js-is-open');
        // set focus
        if (setFocus) {
            button.focus();
        }
        // fire open event
        this._fire('closeSubmenu');
        return this;
    }

    // Toggle methods
    MainNavigation.prototype.toggle = function () {
        var expanded = this.button.getAttribute('aria-expanded') === 'true';
        return expanded ? this.close() : this.open();
    }
    MainNavigation.prototype.toggleSubmenu = function (button) {
        var expanded = button.getAttribute('aria-expanded') === 'true';
        return expanded ? this.closeSubmenu(button) : this.openSubmenu(button);
    }

    // focus menu button
    MainNavigation.prototype.focusMenuButton = function () {
        this.button.focus();
        return this;
    }

    MainNavigation.prototype._fire = function (type, data) {
        if (typeof this._listeners === 'undefined') {
            this._listeners = [];
        }
        var listeners = this._listeners[type] || [];

        listeners.forEach(function (listener) {
            listener(data);
        })
    }

    MainNavigation.prototype.on = function (type, handler) {
        if (typeof this._listeners[type] === 'undefined') {
            this._listeners[type] = [];
        }

        this._listeners[type].push(handler);

        return this;
    }

    MainNavigation.prototype.off = function (type, handler) {
        var index = this._listeners[type].indexOf(handler);

        if (index > -1) {
            this._listeners[type].splice(index, 1);
        }

        return this;
    }

    // Export MainNavigation
    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = MainNavigation;
    } else if (typeof define === 'function' && define.amd) {
        define('MainNavigation', [], function () {
            return MainNavigation;
        })
    } else {
        // attach to window
        window.MainNavigation = MainNavigation;
    }
}());
