import {ready} from "../utils/ready.mjs";

;(function () {
    'use strict';

    ready(function() {
        if (typeof(Storage) !== "undefined") {

            function loadProgressData() {
                if (document.body.getAttribute('data-progress-data-link')) {
                    const $url = document.body.getAttribute('data-progress-data-link');
                    let xhr = new XMLHttpRequest();

                    xhr.onreadystatechange = function () {
                        const DONE = 4; // readyState 4 means the request is done.
                        const OK = 200; // status 200 is a successful return.
                        if (xhr.readyState === DONE) {
                            if (xhr.status === OK) {
                                sessionStorage.setItem("progress", xhr.response);
                                updateCards();
                            }
                        }
                    };
                    xhr.open('GET', $url);
                    xhr.send(null);
                }
            }

            function getProgressData() {
                if (sessionStorage.getItem("progress") !== null) {
                    return JSON.parse(sessionStorage.getItem("progress"));
                }
            }

            function updateCards() {
                // go through cards and update progress
                const items = document.querySelectorAll('.js-card-progress');
                if (items.length > 0) {
                    let progressData = getProgressData();
                    Array.prototype.forEach.call(items, function (item) {
                        let percentage = progressData[item.getAttribute('data-post-id')];
                        if (percentage !== undefined) {
                            // update data
                            item.setAttribute('title', 'Played ' + percentage);
                            item.querySelector('.js-card-progress-bar').style.width = percentage;
                            item.querySelector('.js-card-progress-bar').innerText = 'Played ' + percentage;
                            if (percentage === '100%') {
                                item.querySelector('.js-card-progress-bar').classList.add('Card__progress--bar--finished');
                            }
                            // make item visible
                            item.removeAttribute('hidden');
                        }
                    })
                }
            }

            // load current data
            loadProgressData();
            // reload the progress data once every minute to make sure data is synched between browser tabs
            // (sessionStorage is only valid per tab/window)
            let progressInterval = setInterval(loadProgressData, 180000); // every 3 minutes

        } else {
            console.log('Session storage for progress records not supported')
        }
    });
}());
