import {throttle} from "../utils/throttle.mjs";
import Player from "@vimeo/player";

;(function () {
    'use strict';

    var connection = false;
    var slowConnection = false;
    var reducedMotionQuery = window.matchMedia("(prefers-reduced-motion: reduce)");

    if ("connection" in navigator || "mozConnection" in navigator || "webkitConnection" in navigator) {
        connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
        // update connection speed flag
        slowConnection = (connection.effectiveType === 'slow-2g' || connection.effectiveType === '2g' || connection.effectiveType === '3g');
    }

    if (connection && connection.saveData === true) {
        // data saver is on, don't load web fonts
        console.log('hero-video: data saver');
    } else if (slowConnection) {
        // slow connection, don't load web font
        console.log('hero-video: slow connection');
    } else if (reducedMotionQuery && reducedMotionQuery.matches) {
        // reduced motion: don't load web fonts and prevent reflow
        console.log('hero-video: reduced motion');
    } else {
        console.log('hero-video: load hero video');

        let hero = document.querySelector('.js-hero-video');
        if (typeof(hero) != 'undefined' && hero != null) {
            // get hidden video
            let hidden = hero.querySelector('[hidden]');
            let content = hidden.innerHTML;
            // get content from within html comment
            content = content.replace(/<!--([\S\s]+?)-->/g, '$1');
            // get fallback
            let fallback = hero.querySelector('picture');
            // replace content
            hero.innerHTML = content;
            // add fallback as hidden
            if (typeof(fallback) != 'undefined' && fallback != null) {
                fallback.setAttribute('hidden', 'hidden');
                hero.appendChild(fallback);
            }
            // add class
            hero.classList.add('js-hero-video-loaded');

            // adjust aspect ratio
            window.addEventListener('resize', throttle(function () {
                // adjust styles depending available space
                let containerAspectRatio = hero.clientWidth / hero.clientHeight;
                let iframe = hero.querySelector('iframe');
                let iframeAspectRatio = parseInt(iframe.getAttribute('width')) / parseInt(iframe.getAttribute('height'));
                iframe.style.aspectRatio = iframeAspectRatio;
                if (containerAspectRatio > iframeAspectRatio) {
                    iframe.style.width = '100%';
                    iframe.style.height = 'auto';
                } else {
                    iframe.style.width = 'auto';
                    iframe.style.height = '100%';
                }
            }, 300));
            window.dispatchEvent(new Event('resize'));

            // back to fallback image at the end of the video
            if (hero.classList.contains('js-hero-video-fallback') && typeof(fallback) != 'undefined' && fallback != null) {
                let iframe = hero.querySelector('iframe');
                let player = new Player(iframe);

                let textContent = document.querySelector('.js-hero-content');
                if (hero.classList.contains('js-hero-video-hide-content') && typeof(textContent) != 'undefined' && textContent != null) {
                    textContent.setAttribute('hidden', 'hidden');
                }

                player.on('ended', function() {
                    fallback.removeAttribute('hidden');
                    hero.querySelector('.js-hero-video-embed').remove();

                    if (hero.classList.contains('js-hero-video-hide-content') && typeof(textContent) != 'undefined' && textContent != null) {
                        textContent.removeAttribute('hidden');
                    }
                });
            }
        }
    }
}());
