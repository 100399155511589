import {ready} from "../utils/ready.mjs";

;(function () {
	'use strict';

	ready(function() {
        const uncheckLinks = document.querySelectorAll('.js-uncheck-all');
        if (uncheckLinks.length > 0) {
            console.log('uncheck links found');
            Array.prototype.forEach.call(uncheckLinks, function (link) {
                // click event listener
                link.addEventListener('click', function (e) {
                    console.log('clicked');
                    e.preventDefault();
                    let field = this.getAttribute('data-controls');
                    let boxes = this.closest('form').querySelectorAll('.field.' + field + ' input[type="checkbox"]');
                    if (boxes.length > 0) {
                        console.log('boxes found');
                        Array.prototype.forEach.call(boxes, function (box) {
                            box.checked = false;
                        })
                    }
                })
            })
        }
        const checkLinks = document.querySelectorAll('.js-check-all');
        if (checkLinks.length > 0) {
            console.log('uncheck links found');
            Array.prototype.forEach.call(checkLinks, function (link) {
                // click event listener
                link.addEventListener('click', function (e) {
                    console.log('clicked');
                    e.preventDefault();
                    let field = this.getAttribute('data-controls');
                    let boxes = this.closest('form').querySelectorAll('.field.' + field + ' input[type="checkbox"]');
                    if (boxes.length > 0) {
                        console.log('boxes found');
                        Array.prototype.forEach.call(boxes, function (box) {
                            box.checked = true;
                        })
                    }
                })
            })
        }
	});
}());
