import {ready} from "../utils/ready.mjs";

(function () {
    'use strict';

    // init main navigation
    ready(function () {
        var button = document.querySelector('.js-menu-skiplink-button');
        if (typeof(button) != 'undefined' && button != null) {
            var handler = new MenuSkiplinkHandler(button);

            // handle skip link
            var skipLink = document.getElementById('skip-to-nav');
            skipLink.addEventListener('click', function (e) {
                handler.handleSkipLink(e);
            });
            skipLink.addEventListener('keydown', function (e) {
                if (e.keyCode === 13 || e.keyCode === 32) { // enter or space
                    handler.handleSkipLink(e);
                }
            });
        }
    });

    // Constructor
    function MenuSkiplinkHandler(button)
    {
        var self = this;
        this.button = button;

        // handle nav hash in url
        if (window.location.hash && window.location.hash === "#nav") {
            console.log('hash called');
            this.button.focus();
        }
    }

    // handle skip link
    MenuSkiplinkHandler.prototype.handleSkipLink = function (event) {
        event.preventDefault();
        this.button.focus();
        return this;
    }

    // Export MenuSkiplinkHandler
    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = MenuSkiplinkHandler;
    } else if (typeof define === 'function' && define.amd) {
        define('MenuSkiplinkHandler', [], function () {
            return MenuSkiplinkHandler;
        })
    } else {
        // attach to window
        window.MenuSkiplinkHandler = MenuSkiplinkHandler;
    }
}());
